import React from 'react'
import {Card, Input, Button} from 'antd';
import { useState } from 'react';


  const Workspace = () => {
  const [domain, setDomain] = useState('');
  const handleSearch = async () => {
    console.log(domain);
   // const tldInfo = await checkDomainTLDInfo(domain);

  }
  return (// fullscrenn 
<div style={{ height: '100vh', width: '100vw' }} className='workspaceGradient'>
      <div className='flex flex-row items-center w-fit mx-auto pt-40'>
        <span className='text-slate-900 text-5xl font-medium'>Search for a domain name</span>
      </div>
   
      <div className='flex flex-row items-center w-4/12   pt-12 mx-auto'>
        <Input placeholder='Enter a domain' className='w-full h-12' value={domain} onChange={(e) => setDomain(e.target.value)}/>
        <Button type='primary' variant='dashed' danger size='large' className='h-12 w-32'  onClick={handleSearch}>Search</Button>
      </div>
      

</div>
  )
}

export default Workspace