import React from 'react'
import {message} from 'antd'
import { Link } from 'react-router-dom';

const CtaSection = () => {
  const handleComingSoon = () => {
    message.info('Coming soon!')
  }
  return (
    <div>
  
  <div class="bg-white">
  <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
    <h2 class="max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Ready to dive in? <br/>Start your  trial today.</h2>
    <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
      <Link to="/" onClick={()=>handleComingSoon()} class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</Link>
      <Link to="/aboutus" class="text-sm/6 font-semibold text-gray-900">Learn more <span aria-hidden="true">→</span></Link>
    </div>
  </div>
</div>



    </div>
  )
}

export default CtaSection