import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Input, Button, message, Card } from 'antd';
import axios from 'axios';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'

const HeroSection = () => {
  const [domain, setDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tld, setTld] = useState(null);
  const [domainWithoutTld, setDomainWithoutTld] = useState(null);
  const [tldInfo, setTldInfo] = useState(null);
  const [domainInfo, setDomainInfo] = useState(null);
  const [domainStatus, setDomainStatus] = useState(null);
  const handleSearch = async () => {
    try {
      console.log(domain);
      setDomainInfo(null);
      setTldInfo(null);
      setDomainStatus(null);
      setIsLoading(true);
  
      let formattedDomain = domain.trim();
  
      // Use a regular expression to remove protocols, subdomains, and routes
      const domainPattern = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
      const match = formattedDomain.match(domainPattern);
  
      if (!match || !match[1]) {
        throw new Error('Invalid domain format');
      }
  
      formattedDomain = match[1];
  
      // Remove trailing slashes if any
      if (formattedDomain.endsWith('/')) {
        formattedDomain = formattedDomain.slice(0, -1);
      }
  
      // Extract the top-level domain (TLD)
      const tldMatch = formattedDomain.match(/\.[a-zA-Z]{2,}$/);
      if (!tldMatch) {
        throw new Error('Invalid TLD in domain');
      }
      const extractedTld = tldMatch[0].slice(1);
  
      // API Calls
      setDomainWithoutTld(formattedDomain);
      setTld(extractedTld);
      //axios to fetch

      // https://my-domain-api.shubhankardev8.workers.dev 
      // v1/api/check-domain-availability
      // v1/api/check-domain-info
      //v1/api/check-domain-tld-info 
      const response = await axios.post('http://178.16.138.73:6210/check-domain', {
        domain: formattedDomain,
      });
      console.log(response);
      setDomainStatus(response.data);
  
      const response2 = await axios.post('http://178.16.138.73:6210/check-domain-info', {
        domain: formattedDomain,
      });
      console.log(response2);
      setDomainInfo(response2.data);
  
      const response3 = await axios.post('http://178.16.138.73:6210/check-domaintld-info', {
        tld: extractedTld,
      });
      console.log(response3);
      setTldInfo(response3.data);
  
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      message.error('Error fetching domain information. Please check the input and try again.');
    }
  };
  const handleComingSoon = () => {
    message.info('Coming soon!')
  }
  return (
    <div>
     <div className="bg-white">
  <header className="absolute inset-x-0 top-0 z-50">
    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div className="flex lg:flex-1">
        <a href="/" className="-m-1.5 p-1.5 flex flex-row-reverse items-center gap-2">
          <span className="text-2xl font-bold">DomainCatch</span>
          <img className="h-8 w-auto" src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600" alt=""/>
        </a>
      </div>
      {/* <div className="flex lg:hidden">
        <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
          <span className="sr-only">Open main menu</span>
          <svg className="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div> */}
      {/* <div className="hidden lg:flex lg:gap-x-12">
        <a href="/" className="text-sm/6 font-semibold text-gray-900">Product</a>
        <a href="/" className="text-sm/6 font-semibold text-gray-900">Features</a>
        <a href="/" className="text-sm/6 font-semibold text-gray-900">Marketplace</a>
        <a href="/" className="text-sm/6 font-semibold text-gray-900">Company</a>
      </div> */}
      {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
        <a href="/" className="text-sm/6 font-semibold text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a>
      </div> */}
    </nav>
{/*     
    <div className="hidden" role="dialog" aria-modal="true">
  
      <div className="fixed inset-0 z-50"></div>
      <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto" src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600" alt=""/>
          </a>
          <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700">
            <span className="sr-only">Close menu</span>
            <svg className="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <a href="/" className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Product</a>
              <a href="/" className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Features</a>
              <a href="/" className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Marketplace</a>
              <a href="/" className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Company</a>
            </div> 
            <div className="py-6">
              <a href="/" className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Log in</a>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </header>


{/* search bar */}
<div className='w-full md:pt-0 pt-20'>
  <img src='https://static.nc-img.com/pp/cms/domain-name-search/images/banner.a6349ec79a534f740bebc287b2851abc.svg' alt="hero-bg" className='w-full h-72 object-cover border-b-2 border-gray-200' />
</div>
<div className='flex flex-row items-center w-fit mx-auto pt-5 z-40'>
        <span className='text-slate-900 text-4xl md:text-6xl font-medium'>Search for a domain name</span>
  </div>
   
  <div className='flex flex-row items-center lg:w-4/12 w-11/12   pt-12 mx-auto'>
        <Input placeholder='Enter a domain' className='w-full h-12' value={domain} disabled={isLoading} onChange={(e) => setDomain(e.target.value)}/>
        <Button type='primary' variant='dashed' danger size='large' className='h-12 w-32'  onClick={()=>handleSearch()}  loading={isLoading}>Search</Button>
  </div>


{/* saerch bar */}


        {/* cards */}
  {domainStatus && domainInfo && tldInfo && (
  <div className='flex lg:flex-row flex-col  items-center w-full justify-evenly'>
        <Card title={'Domain Status :'+` ${domainWithoutTld}`} className='w-11/12 lg:w-3/12  mt-8 h-72' style={{boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)', overflowY:'auto', wordWrap:'break-word'}}>
        <div style={{overflowY:'auto', wordWrap:'break-word', height:'100%', width:'100%'}} className='h-64 w-full'>
        <JsonView src={domainStatus} />
        </div>
        
        </Card>

        <Card title={'Domain Info :'+` ${domainWithoutTld}`} className='w-11/12 lg:w-3/12 mt-8 h-72' style={{boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)', overflowY:'auto', wordWrap:'break-word'}}>
        <div style={{overflowY:'auto', wordWrap:'break-word', height:'100%', width:'100%'}} className='h-64 w-full'>
        <JsonView src={domainInfo} />
        </div>
        
        </Card>

        <Card title={'TLD Info :'+` ${tld}`} className='w-11/12 lg:w-3/12 mt-8 h-72' style={{boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)', overflowY:'auto', wordWrap:'break-word'}}>
        <div style={{overflowY:'auto', wordWrap:'break-word', height:'100%', width:'100%'}} className='h-64 w-full'>  
        <JsonView src={tldInfo} />
        </div>
        </Card>
  </div>)}
        

        {/* cards */}
  <div className="relative isolate px-6 pt-0 lg:px-8">
    {/* <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
      <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}}></div>
    </div> */}
    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
      <div className="hidden sm:mb-8 sm:flex sm:justify-center">
        {/* <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
          Announcing our next round of funding. <a href="/" className="font-semibold text-indigo-600"><span className="absolute inset-0" aria-hidden="true"></span>Read more <span aria-hidden="true">&rarr;</span></a>
        </div> */}
      </div>
      <div className="text-center">
        <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">Never miss a domain expiry again</h1>
        <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">Get info on any domain on the internet, then get notified when new changes are detected</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link to="/" onClick={()=>handleComingSoon()} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</Link>
          <a href="/aboutus" className="text-sm/6 font-semibold text-gray-900">Learn more <span aria-hidden="true">→</span></a>
        </div>
      </div>
    </div>
    <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
      <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}}></div>
    </div>
  </div>
</div>


    </div>
  )
}

export default HeroSection