import React from 'react';
import './App.css'; 
import Homepage from './screen/homepage'
import Workspace from './screen/workspace'
import SignIn from './screen/signIn'
import SignUp from './screen/signUp'
import AboutUs from './screen/aboutUs'
import ContactUs from './screen/contactUs'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/workspace" element={<Workspace />} /> 
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
 
  );
}

export default App;
