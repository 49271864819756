import React from 'react'
import HeroSection from '../components/HeroSection'
import FeatureSection from '../components/FeatureSection'
import CtaSection from '../components/CtaSection'
import Footer from '../components/Footer'

const Homepage = () => {
  return (
    <div>
    <div class="bg-emerald-50">
        <main>
            <HeroSection />
            <FeatureSection />
            <CtaSection />
        </main>

        <Footer />
    </div>
</div>
  )
}

export default Homepage